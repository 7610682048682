.orga {
  background: url("../images/fond/orga2.png") center center no-repeat;
  background-size: 100% 100%;
  padding: 30px;
  text-align: center;

  h3 {
    clear: both;
    font-size: 14pt;
    font-family: "godofwarregular";
    font-weight: normal;
    text-align: center;
    margin: 0px 0px 5px 0px;
  }

  p {
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-size: 1.2em;

    span.text-small {
      font-size: 0.75em;
    }

    span.text-small:before {
      content: " ";
      clear: right;
      display: block;
    }
  }

  img {
    -webkit-filter: drop-shadow(5px 5px 5px #fff);
    filter: drop-shadow(5px 5px 5px #fff);
  }
}
.descript {
  border: thick ridge #cb8e00;
  border-radius: 10px;
  padding: 0 10px;
  margin: 10px;
  background: rgba(255, 255, 255, 0.7);

  h2 {
    font-size: 20pt;
    font-family: "godofwarregular";
    color: #ffb129;
    margin-top: 10px;
  }
  p {
    font-size: 11pt;
  }
}
