div[class^="unit-"] {
  margin-top: 3vmin;
  text-align: center;
  margin-bottom: 40px;
}
.padl {
  img {
    position: relative;
    left: -15px;
  }
}
.icon {
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

table.content-stats {
  width: 100%;
  border-collapse: collapse;
  font-size: 100%;
  margin: 0 auto;

  tr {
    border-bottom: 3px inset #cb8e00;
  }
  .adjust {
    th:first-child,
    td:first-child {
      width: 40%;
      text-align: center;
    }
    th,
    td {
      width: 10%;
      text-align: center;
    }
    td:last-child {
      width: 10%;
      text-align: center;
    }
  }

  thead {
    font-weight: bold;
    background-color: #450c01;
    border-bottom: 3px inset #cb8e00;
    text-shadow:
      #000000 -1px -1px 0,
      #000000 -1px 1px 0,
      #000000 1px -1px 0,
      #000000 1px 1px 0;
  }
  th,
  td {
    text-align: center;
    font-weight: bold;
    vertical-align: middle;
    padding: 3px;
  }

  td {
    text-align: center;
    border-collapse: collapse;
    font-size: 100%;
    padding: 5px 0;
    min-width: 20px;
    min-height: 30px;

    input[type="text"],
    input[type="number"] {
      width: 50px;
      text-align: center;
    }
  }

  th {
    color: white;
  }

  td:first-child {
    text-align: left;
    padding: 3px;
  }

  &.stats-global,
  &.stats-defensive,
  &.stats-offensive {
    width: 100%;
    padding: 0 1vmin;

    &:first {
      margin-top: 2vmin;
    }

    td {
      border: 0;
      width: 5%;

      &[colspan="2"] {
        width: 10%;
      }

      &:first-child {
        width: 15%;
        text-align: left;
      }

      &:last-child {
        width: 100%;
        text-align: left;
      }
    }

    .adjust {
      td:last-child {
        width: 10%;
        text-align: center;
      }
    }

    thead {
      color: #fff;
      font-style: italic;
    }

    tbody {
      td {
        font-weight: bold;
        ul {
          margin: 2px;
        }
      }
    }

    &.stats-v2-inline {
      td:first-child {
        width: 20%;
      }

      td {
        width: 8%;
        text-align: left;
        font-weight: normal;
      }

      td:last-child {
        width: 45%;
      }

      span {
        color: gray;
      }
    }
  }

  &.stats-global {
    border-radius: 10px;
  }
  &.stats-defensive {
    border-radius: 10px;
  }
  &.stats-offensive {
    border-radius: 10px;
  }
}

.unit-rules,
.unit-model_rules {
  ul {
    list-style: inside;
    margin-left: 2vmin;
  }

  .troop-rules,
  .troop-model_rules {
    margin-left: 2vmin;
  }
}

.text-italic {
  font-style: italic;
}

.content-stats ul li a,
.content-stats ul li a:visited,
.content-stats ul li a:focus,
.content-stats ul li a:hover {
  color: black;
}

.options {
  .option {
    display: grid;
    grid-template-columns: auto 20%;
    grid-auto-rows: auto;
    grid-template-areas:
      "name points"
      "infos .";

    label {
      grid-area: name;

      background: url("../images/fond/puce2.png");
      background-repeat: no-repeat;
      background-position: 0 0.5em;
      padding-left: 15px;
    }

    .infos {
      grid-area: infos;

      margin: 0;
      padding: 0 0 0 20px;
      background: none;
      font-size: smaller;
    }

    .points {
      grid-area: points;
    }
  }
}
