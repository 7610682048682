article[class^="version-"] {
  padding: 10px;
  min-height: 150px;
  cursor: pointer;
  position: relative;
}

article {
  //    margin: 10px;
}

section {
  h2,
  h3,
  h4 {
    clear: both;
    font-family: "godofwarregular";
    font-weight: normal;
    text-align: center;
  }

  a strong {
    color: black;
    font-weight: normal;
    text-align: center;
    text-decoration: none;
  }

  a strong:hover,
  a:hover {
    color: #cb8e00;
    text-decoration: none;
  }

  .rulespe,
  .spell {
    h3 {
      font-family: "godofwarregular";
      color: #56251a;
      font-weight: normal;
      text-align: center;
      margin: 0px 0px 5px 0px;
      padding: 5px;
    }

    p {
      padding: 10px;
      text-align: justify;
    }
  }
}

.rulespe {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
