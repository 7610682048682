.magic-category-list {
  img.magic-item-catgory:before {
    content: " ";
    display: block;
    position: absolute;
    height: 132px;
    width: 220px;
    background-image: url("../images/en/bt_lexicon_entry.png");
  }
}
