$list-group-bg: none;
$list-group-border-color: none;
$list-group-border-width: 0;
$grid-float-breakpoint: 1000px;
@import "~bootstrap/scss/bootstrap";

$fa-font-path: "@fortawesome/fontawesome-free/webfonts";
@import "@fortawesome/fontawesome-free/scss/fontawesome";
@import "@fortawesome/fontawesome-free/scss/solid";
@import "@fortawesome/fontawesome-free/scss/brands";

@import "../stylesheets/common/pagination.scss";
@import "../stylesheets/common/loader.scss";
@import "../stylesheets/common/common.scss";
@import "../stylesheets/common/devise_bootstrap_views.scss";
@import "../stylesheets/common/tab.scss";
@import "../stylesheets/ninth_age/unit.scss";
@import "../stylesheets/ninth_age/magic_item.scss";
@import "../stylesheets/ninth_age/army.scss";
@import "../stylesheets/ninth_age/organisation.scss";
@import "../stylesheets/ninth_age/version.scss";
@import "../stylesheets/ninth_age/special_rule.scss";
@import "../stylesheets/ninth_age/magic.scss";
@import "../stylesheets/data_analytics/report.scss";
@import "../stylesheets/common/multi-color-progress-bar.scss";

.army_list_row {
  position: relative;
}

.section-title {
  margin-top: 70px;
  margin-bottom: 15px;

  h3 {
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 2px;
    line-height: 48px;
    display: inline-block;
    *display: inline;
    *zoom: 1;
    clear: both;
    position: relative;
    padding: 0 10px;
  }

  p {
    font-family: "Oswald";
    text-transform: uppercase;
    font-size: 38px;
    line-height: 58px;
    font-weight: 700;
    letter-spacing: 3px;
  }
}

.section-title.st-center {
  text-align: center;

  &:before {
    margin: auto;
  }
}

.tooltip.top .tooltip-arrow {
  bottom: 1px;
}

.tac {
  text-align: center;
}

html[lang="en"] .home .signin a {
  margin-left: 0px;
}

.border {
  border: 5px ridge #ffb129;
}

#no-script {
  background-color: red;
  border: 2px solid black;
  display: block;
  margin: 0 auto;
  color: white;
  font-weight: bold;
  font-size: 3vmin;
  padding: 1.5vmin;
  z-index: 1000;
  text-align: center;
}

.platformdev {
  height: 100px;
}

// mise en page de la home page

// Titre
h1,
h2,
h3 {
  font-family: "godofwarregular";
  color: #470b03;
}

h2 {
  em {
    color: #ffb129;
    font-style: normal;
  }
}

#corps {
  padding-bottom: 100px;

  h3 {
    em {
      font-style: normal;
    }
  }

  iframe {
    margin: 10px;
  }

  @media only screen and (max-device-width: 480px) {
    iframe {
      margin: 0%;
      padding: 0%;
    }
  }

  strong {
    font-size: 18pt;
    font-weight: bold;
    color: #a01e14;
  }

  center {
    margin-top: 20px;

    p {
      padding: 20px;
    }
  }

  span {
    strong {
      font-weight: bold;
      font-size: 16pt;
    }
  }

  p {
    font-size: 13pt;
  }
}

.limit {
  padding: 20px;
  font-size: 12pt;
  background: rgba(255, 255, 255, 0.7);
}

.panel-body {
  clear: both;
}

em {
  font-family: "lora";
  color: #ffb129;
}

.page-content {
  padding: 120px 0;
}

.about {
  padding: 100px 0 0;
}

.ressources {
  text-align: center;
  background-color: #56251a;
}

footer.site-footer {
  background-color: #343b57;
  padding: 10px 0;
  color: #9f9f9f;
  font-size: 13px;
  text-align: center;

  position: fixed;
  bottom: 0;
  width: 100%;

  a,
  a:hover {
    color: #dce0df;
  }
}

// liens FB news
.fbnews {
  background: url("../images/fond/fbnews.png") no-repeat;
  width: 525px;
  height: 320px;
  overflow: hidden;
  padding: 14px;
  scrolling: no;
  frameborder: 0;
  allowtransparency: true;
  allow: encrypted-media;
}

@media only screen and (max-device-width: 480px) {
  .fbnews {
    background: no-repeat;
    width: auto;
    height: 400px;
    overflow: hidden;
    padding: 0px;
    scrolling: no;
    frameborder: 0;
    allowtransparency: true;
    allow: encrypted-media;
  }
}

center {
  width: 50%;

  .center-div {
    margin: 0 auto;
    width: 100px;
  }
}

/*roll hover contact    */
.contact {
  background: url("../images/links/contactus.webp") no-repeat;
  width: 80px;
  height: 80px;

  &:hover {
    background: url("../images/links/contactus1.webp") no-repeat;
  }
}

.newhead {
  background: url("../images/header/sword.png") no-repeat center;
  background-size: auto 95%;
  background-position: center;
  height: 100%;

  .logleft {
    background: url("../images/header/log_left.png") no-repeat left;
    background-size: auto 100%;
    height: 100%;
  }

  .logright {
    background: url("../images/header/log_right.png") no-repeat right;
    background-size: auto 100%;
    height: 100%;
  }
}

@media only screen and (max-device-width: 600px) {
  .newhead {
    background: url("../images/header/9thmobile.png") no-repeat center;
    background-size: auto 100%;
    height: 100%;

    .logleft {
      background: url("../images/header/logmob_left.png") no-repeat left;
      background-size: auto 100%;
      height: 100%;
    }

    .logright {
      background: url("../images/header/logmob_right.png") no-repeat right;
      background-size: auto 100%;
      height: 100%;
    }
  }
}

.lexicon-element {
  //  padding-bottom: 40px;

  .magic {
    padding: 15px 0;
    margin: 10px;
  }

  p {
    margin: 10px;
  }

  i,
  a.admin {
    color: blue;
  }

  .content-stats {
    color: black;
    text-align: center;
  }
}

.clear-both {
  clear: both;
}

.block-center {
  margin: 0 auto;
  display: block;
}

#links {
  bottom: 0px;
  width: 100%;

  a {
    color: black;
    text-decoration: none;
  }

  a:hover {
    color: #cb8e00;
    text-decoration: none;
  }

  img {
    max-height: 75px;
  }
}

.list-locales {
  li.active {
    border-radius: 0.5rem !important;
    background-color: rgba(71, 11, 3, 0.3);
  }
}

.common-model-rules {
  label {
    color: black;
  }

  .list-inline {
    .list-inline-item {
      margin: 0;
    }

    li + li:before {
      content: ", ";
    }
  }
}
