.nav-pills {
  li > a {
    color: #6c3c35 !important;
  }
  > li.active {
    > a,
    > a:hover,
    a:focus {
      color: #fff !important;
      background-color: #6c3c35 !important;
    }
  }
}
