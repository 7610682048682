.armies {
  div[class^="army-"] {
    min-height: 200px;
  }
  .picture {
    position: relative;
    width: 100%;

    img {
      width: 100%;
      margin: 0 auto;
    }
    .namearmy {
      position: absolute;
      top: 1%;
      margin-right: 5%;
      left: 8%;
      font-size: 14px;
      font-weight: bold;
      color: white;
      text-align: left;
      text-shadow:
        #000000 -1px -1px 0,
        #000000 -2px -2px 0,
        #000000 -1px 1px 0,
        #000000 -2px 2px 0,
        #000000 1px -1px 0,
        #000000 2px -2px 0,
        #000000 2px 2px 0,
        #000000 3px 3px 0,
        #000000 4px 4px 0,
        rgba(0, 0, 0, 0.1) 0 5px 1px,
        rgba(0, 0, 0, 0.1) 0 0 5px,
        rgba(0, 0, 0, 0.3) 0 1px 3px,
        rgba(0, 0, 0, 0.15) 0 3px 5px;
    }
  }
}

.army_magic_spell {
  .red {
    color: red;
  }
  .blue {
    color: blue;
  }

  table {
    border: 5px inset #cb8e00;
    width: 100%;

    thead {
      background-color: #450c01;
      color: #fff;
      font-style: italic;
      border-bottom: 3px inset #cb8e00;
      text-shadow:
        #000000 -1px -1px 0,
        #000000 -1px 1px 0,
        #000000 1px -1px 0,
        #000000 1px 1px 0;
    }

    th,
    td {
      text-align: center;
      font-weight: bold;
      vertical-align: middle;
    }
    th {
      text-shadow:
        #000000 -1px -1px 0,
        #000000 -1px 1px 0,
        #000000 1px -1px 0,
        #000000 1px 1px 0;
    }

    th:first-child,
    td:first-child {
      width: 5%;
      font-style: italic;
      font-weight: bold;
      color: #cb8e00;
    }
    .name {
      width: 5%;
      height: 20px;
      font-style: italic;
      font-weight: bold;
      color: #cb8e00;
      border-bottom: 3px inset #cb8e00;
    }
    .effect {
      border-left: 3px inset #cb8e00;
    }
    tbody {
      &:tr:first-child td:first-child {
        font-size: 12px;
        color: #cb8e00;
      }

      .casting_value,
      .range,
      .spell_type {
        span {
          display: block;
        }
      }
    }
  }
}

.armies {
  clear: both;
}
.army-img {
  clear: both;
  height: auto;

  &:before {
    content: " ";
    display: block;
    position: absolute;
    height: 132px;
    width: 220px;
    background-image: url("../images/en/bt_lexicon_entry.png");
  }
}

.profil {
  background: url("../images/fond/profil2.png") center center no-repeat;
  background-size: 100% 100%;
  padding: 10px 0px 10px 0px;
}

.section-organisation {
  h1 {
    clear: both;
    font-size: 40pt;
    font-family: "godofwarregular";
    color: #ffb129;
    font-weight: normal;
  }
}
.nomunit {
  float: left;
}
.socle {
  background: url("../images/fond/profil2.png");
  background-size: 102% 102%;
  padding: 15px;

  em {
    font-family: "lora";
    font-size: 13pt;
    color: #470b03;
    text-shadow:
      #c7c7c7 0px 1px 0,
      #c7c7c7 0px -1px 0,
      #c7c7c7 -1px 0px 0,
      #c7c7c7 1px 0px 0;
  }
}
.iconorga {
  float: left;
}
