@import "./_global.scss";

.clear-none {
  clear: none !important;
}

.line {
  white-space: pre-line;
}

.borderid {
  background-image: url("../images/fond/bg_popin.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.borderso {
  border: 5px solid #ffb129;
}

.borderdot {
  border: medium dotted;
}

html {
  height: 100vh;
}

body {
  font-family: "Lora", serif !important;
  font-size: 11pt !important;
  color: #470b03;
  background: url("../images/fond/bg_body.jpg") 100% 0 repeat;
  min-height: 100vh;
  height: auto;

  &.full {
    background-color: #56251a;
  }
}

.bg-ninthAge {
  background: url("../images/fond/bg_body2.png") no-repeat center fixed;
  height: auto;
  min-height: 90vh;
}
.bg-battle {
}
.bg-essenceOfWar {
}
.bg-theOldWorld {
}

$navbar-dark-brand-color: "white";
$navbar-dark-brand-hover-color: "white";
$navbar-dark-active-color: "white";
$navbar-dark-color: "rgba(255,255,255,.55)";
$navbar-dark-hover-color: "white";
$navbar-dark-toggler-border-color: "rgba(255,255,255,.1)";
$navbar-dark-active-color: "white";
$navbar-dark-disabled-color: "gray";

header {
  background-color: #470b03;

  &#wrapper_home {
    background: url("../images/header/bg_home1.png") repeat-x;
    height: 100px;
  }

  .navbar {
    padding: 0 1rem;

    &.bg-age {
      background-color: #470b03;
      background: url("../images/header/bg_header.png") 50% 0 repeat-x;
      background-position: bottom;
    }

    .navbar-brand {
      height: auto;
      transition: padding 0.3s;
      color: #fff;
      font-family: "godofwarregular";
      text-decoration: none;
    }

    .navbar-toggler {
      margin: 10px;
    }

    .navbar-nav li {
      .lang {
        border: thick ridge #cb8e00;
      }

      & > .open > a,
      & > li > .dropdown-menu {
        background-color: #450c01;
        border-radius: 10px;
      }

      & > li > .dropdown-menu > li > a {
        color: white !important;
      }

      & > li > .dropdown-menu > li > a:hover,
      & > li > .dropdown-menu > li > a:focus {
        color: #cb8e00;
        background-color: rgba(0, 0, 0, 0);
      }

      iframe {
        padding-top: 15px;
      }

      a {
        color: white !important;
        text-decoration: none;

        &:hover,
        &:focus {
          color: #cb8e00;
        }

        &:visited {
          color: white;
        }
      }

      .dropdown-menu {
        background-color: #470b03;

        a {
          color: white !important;
        }

        a:hover {
          color: #cb8e00;
        }
      }
    }
  }
}

a {
  color: #470b03;
  text-decoration: underline;

  &:hover {
    color: #a01e14;
    text-decoration: none;
  }
}

form.button_to,
form.button_to div {
  display: inline;
}

#wrapper {
  background: url("../images/header/bg_header.png") 50% 0 repeat-x;
  position: relative;
  top: 0%;
  height: 60px;
}

#header {
  position: relative;
  height: 44px;
  color: #fff;

  h1 {
    margin-top: 2px;
    font-size: 14px;
    font-weight: bold;

    em {
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
    }

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  .menu,
  .menu-lang {
    display: block;
    position: absolute;
    top: 38px;
    right: 10px;
    min-width: 120px;
    list-style: none;
    padding: 5px 10px;
    background-color: #6c3c35;
    -webkit-border-bottom-right-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-radius-bottomright: 5px;
    -moz-border-radius-bottomleft: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    z-index: 100;

    li {
      margin: 5px 0;
    }

    a {
      color: #fff;
      text-decoration: none;
      width: 100%;
      text-align: left;
      padding: 0;
      height: 45px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .welcome {
    text-align: right;

    strong,
    a {
      float: right;
      display: block;
      padding: 10px 10px 0 10px;
      height: 28px;
      font-size: 13px;
      font-weight: normal;
    }

    a {
      color: #fff;
      text-decoration: none;
    }

    strong:hover,
    strong.over,
    a:hover {
      cursor: pointer;
      background-color: #6c3c35;
    }

    img {
      position: relative;
      top: -2px;
    }
  }
}

#subheader {
  font: 18px "godofwarregular";

  background-color: rgba(71, 11, 3, 0.2);
  -webkit-border-bottom-right-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-bottomright: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-radius: 5px;
  //  border-bottom-left-radius: 5px;

  p {
    margin: 0 !important;
    text-align: center;
    font-size: 16px;
  }

  a {
    text-decoration: none;

    & > i {
      color: black;
    }

    &.open-action,
    &.open {
      i {
        color: #337ab7;
      }
    }
  }
}

.breadcrumbs,
.breadcrumb {
  clear: both;
  position: relative;
  margin: 0px 0px 0px 0px;
  padding: 5px;
  //  font-size: 12px;
  text-align: left;
  width: 100%;

  a:link,
  a:hover,
  a:focus,
  a:active,
  a:visited {
    color: brown;
    text-decoration: underline !important;
  }

  a:hover {
    color: #cb8e00;
    text-decoration: underline !important;
  }

  span.current {
    color: black;
  }
}

#content {
  position: relative;
}

h1,
h2,
h3,
h4 {
  font-family: "godofwarregular";
  text-align: center;
}

h1 {
  em {
    font-weight: normal;
    font-style: normal;
  }
}

h2 {
  span {
    font-size: 20pt;
  }

  em {
    font-family: "godofwarregular";
    color: #ffb129;
    font-style: normal;
  }
}

h3 {
  font-weight: normal;
  margin: 0px 0px 5px 0px;

  em {
    font-family: "godofwarregular";
    color: #ffb129;
    font-style: normal;
  }
}

h4 {
  margin: 0px 0px 5px 0px !important;
}

a strong {
  color: black;
  font-weight: normal;
  text-align: center;
  text-decoration: none;
  font-size: 11pt;
}

a strong:hover,
a:hover {
  color: #cb8e00;
  text-decoration: none;
}

p {
  font-size: 13pt;
}

#footer {
  background: url("../images/header/bg_footer.png") 50% 0 repeat-x;
  //  background: rgba(255, 255, 255, 0.5);
  //  position: fixed !important;
  bottom: 0px !important;
  left: 0;
  right: 0;

  .links {
    color: #470b03;
    text-align: center;
  }

  a {
    color: white;
    text-decoration: none;
  }

  a:hover {
    color: #cb8e00;
    text-decoration: none;
  }
}

img.responsive {
  width: max-content;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.popin {
  overflow: hidden;
  -moz-border-image: url("../images/fond/border_popin.png") 20 20 10 20 stretch
    stretch;
  -webkit-border-image: url("../images/fond/border_popin.png") 20 20 10 20
    stretch stretch;
  border-image: url("../images/fond/border_popin.png") 20 20 10 20 stretch
    stretch;
  border-width: 20px 20px 10px 20px;
  border-style: solid;

  label {
    font-weight: normal;
  }

  h1,
  h3 {
    font-family: "godofwarregular";
    font-weight: normal;
  }

  h1 {
    margin-bottom: 0px;
    font-size: 16pt;
  }

  h3 {
    margin-bottom: 5px;
    font-size: 20pt;
  }

  p {
    margin: 10px 0;
    font-size: 13px;

    &.close {
      margin-top: 20px;
      text-align: center;
    }
  }
}

.error_messages {
  width: 400px;
  border: 2px solid #cf0000;
  padding: 0px;
  padding-bottom: 12px;
  margin-bottom: 20px;
  background-color: #f0f0f0;
  font-size: 12px;

  h2 {
    text-align: left;
    font-weight: bold;
    padding: 5px 10px;
    font-size: 12px;
    margin: 0;
    background-color: #c00;
    color: #fff;
  }

  p {
    margin: 8px 10px;
  }
}

.field_with_errors {
  display: inline;

  .message {
    color: #d00;
    padding-left: 5px;
    font-size: 12px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.center-action-new-element {
  text-align: center;
  padding-top: 10em;

  a {
    padding: 2em;
  }
}

.login {
  margin-bottom: 25px;
  padding-bottom: 20px;

  &.home {
    background: url("../images/fond/bg_army_list_value_points.png") center
      center no-repeat;
    background-size: 100% 100%;
  }

  &.sign {
    input {
      height: 30px;
      border-radius: 10px;
    }

    input[type="submit"] {
      font-size: 13px;
      font-weight: bold;
      height: auto;
      border-radius: 10px;
    }

    a {
      color: #ffb129;
      text-decoration: none;
    }

    em {
      font-family: "godofwarregular";
      display: block;
      font-size: 14pt;
      text-align: center;
      color: #ffb129;

      a:hover {
        text-shadow: 1px 1px rgba(0, 0, 0, 0.75);
        color: #ffb129;
      }
    }
  }

  .remember {
    width: 20px;
    margin: 0px;
  }

  label {
    font-family: "godofwarregular";
    font-size: 16pt;
    color: #ffb129;
    text-shadow:
      #000000 0 1px 0,
      #0b0b0b 0 2px 0,
      #0f0f0f 0 3px 0,
      #b9b9b9 0 4px 0,
      #aaa 0 5px 0,
      rgba(0, 0, 0, 0.1) 0 6px 1px,
      rgba(0, 0, 0, 0.1) 0 0 5px,
      rgba(0, 0, 0, 0.3) 0 1px 3px,
      rgba(0, 0, 0, 0.15) 0 3px 5px,
      rgba(0, 0, 0, 0.2) 0 5px 10px,
      rgba(0, 0, 0, 0.2) 0 10px 10px,
      rgba(0, 0, 0, 0.1) 0 20px 20px;
    padding-top: 10px;
  }
}

#user_remember_me {
  position: relative;
  top: 4px;
  height: 15px;
}

label[for="user_remember_me"] {
  font-size: 12pt;
  opacity: 0.9;
}

.links {
  color: #470b03;

  img {
    position: relative;
    top: -2px;
    margin: 10px;
  }
}

.highlight-red {
  color: red !important;
}

.highlight-blue {
  color: blue !important;
}

.hidden {
  display: none;
}

.center-btn {
  display: block;
  margin: auto !important;
}

.gravatar-big {
  border-radius: 20px;
  box-shadow: 4px 6px 16px 3px rgba(87, 87, 87, 0.8);
}

.block-center {
  margin: 0 auto;
  display: block;
}

.min-w-250 {
  min-width: 250px;
}

.box-shadow {
  box-shadow: 0 2px 8px 0 rgba(47, 9, 0, 0.5);
}

.shadow-white {
  text-shadow:
    #fff 0px 1px 0,
    #fff 0px -1px 0,
    #fff -1px 0px 0,
    #fff 1px 0px 0;
}

.read-only,
.read-only * {
  text-decoration: line-through;
  color: gray;
  font-style: italic;
}

.signin {
  input {
    height: 30px;
    color: #333333;
  }

  input[type="submit"] {
    font-size: 14px;
    font-weight: bold;
    height: auto;
  }

  a {
    margin-left: 13px;
    font-size: 10px;
    color: #fff;
    opacity: 0.9;
  }
}

.signup {
  margin-top: 76px;

  input {
    height: 30px;
    color: #333333;
  }

  strong {
    display: block;
    font-size: 16px;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.75);
  }

  p {
    margin-top: 8px;
  }

  input[type="text"],
  input[type="email"],
  input[type="password"] {
    width: 100%;
  }

  input[type="submit"] {
    font-size: 14px;
    font-weight: bold;
    height: auto;
  }
}

#user_remember_me {
  position: relative;
  top: 4px;
  height: 15px;
}

label[for="user_remember_me"] {
  font-size: 10px;
  opacity: 0.9;
}

h1 {
  em {
    font-weight: normal;
    font-style: normal;
  }
}

.links {
  color: #470b03;

  img {
    position: relative;
    top: -2px;
    margin: 0 10px;
  }
}

html[lang="en"] .home .signin a {
  margin-left: 50px;
}

#no-script {
  background-color: red;
  border: 2px solid black;
  display: block;
  margin: 0 auto;
  color: white;
  font-weight: bold;
  font-size: 3vmin;
  padding: 1.5vmin;
  z-index: 1000;
  text-align: center;
}

.platform {
  max-height: 10vmin;
  margin: 10px;
}

.clearboth,
.clear {
  clear: both;
}

.tourne {
  transform: rotate(45deg);
  position: absolute;
  top: -5px;
  right: -35px;
  width: 120px;
  height: 1px;
  border: 1px solid #000;
  border-width: 20px;
  color: white;
  text-align: center;

  &.red {
    border-color: transparent transparent #f00 transparent;
  }
}

.content-stats,
.stats-perso,
.stats-mount {
  li + li::before {
    content: ", ";
  }

  .list-inline-item:not(:last-child) {
    margin-right: 0;
  }
}
