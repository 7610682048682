$margin: 50px;
$margin-small: 5vmin;

$fixed-logo-height: 40px;
$fixed-logo-height-small: 9vmin;

// media queries
$phone-width: 480px;
$tablet-width: 768px;
$desktop-width: 1024px;

$mobile: "all and (max-device-width: #{$phone-width}) and (max-device-width: #{$tablet-width - 1px}) and (max-width: #{$phone-width})";
$tablet: "all and (min-device-width: #{$tablet-width}) and (max-device-width: #{$desktop-width}) and (min-width: #{$phone-width}) and (max-width: #{$desktop-width})";
$desktop: "all and (min-device-width: #{$desktop-width + 1}) and (min-width: #{$desktop-width + 1})";
$portrait: "all and (orientation: portrait)";
$landscape: "all and (orientation: landscape)";

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

/* FONT SIZES */
$font-xxl: 8.5rem;
$font-xl: 6rem;
$font-l: 4.5rem;
$font-ml: 3rem;
$font-m: 2.1rem;
$font-ms: 1.8rem;
$font-s: 1.6rem;
$font-xs: 1.4rem;
$font-xxs: 1.2rem;

/* SPACINGS */
$spacing-xl: 4rem;
$spacing-l: 3rem;
$spacing-m: 2rem;
$spacing-s: 1.6rem;
$spacing-xs: 0.4rem;

p {
  margin: 0;
}

.futura {
  font: 100% Futura;
  font-weight: bold;
  color: #000000;
  font-size: 5.5vmin;
  text-transform: uppercase;
}

.paragraph {
  &:not(:first-of-type) {
    margin-top: 1.5em;
  }

  @media #{$mobile} {
    &:not(:first-of-type) {
      margin-top: 1em;
    }
  }
}

.yellow {
  background: #ffdd00;
}

// Square css transitions (appearance animation)
.square {
  -webkit-transition:
    height 1s cubic-bezier(0.165, 0.84, 0.44, 1),
    width 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition:
    height 1s cubic-bezier(0.165, 0.84, 0.44, 1),
    width 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition:
    height 1s cubic-bezier(0.165, 0.84, 0.44, 1),
    width 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  transition:
    height 1s cubic-bezier(0.165, 0.84, 0.44, 1),
    width 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  -webkit-transition-delay: 0s, 0.6s;
  -moz-transition-delay: 0s, 0.6s;
  -o-transition-delay: 0s, 0.6s;
  transition-delay: 0s, 0.6s;
}

body {
  padding: 0;
  margin: 0;
}

.navbar {
  margin-bottom: 0;
}

.small-text {
  font-size: 1.5vmin;
}

.btn-age {
  color: #ffffff;
  background-color: #56251a;
  border-color: #2f0900;
  font-size: 10pt !important;

  &:hover {
    background-color: #bc8f8f;
    border-color: #56251a;
    color: #fff !important;
  }
}

.btn-age:link,
.btn-age:focus,
.btn-age:active,
.btn-age:visited,
.btn-age.active,
.open .dropdown-toggle.btn-age {
  color: #ffffff !important;
  background-color: #360a00;
  border-color: #2f0900;
}

.btn-age:active,
.btn-age.active,
.open .dropdown-toggle.btn-age {
  background-image: none;
}

.btn-age.disabled,
.btn-age[disabled],
fieldset[disabled] .btn-age,
.btn-age.disabled:hover,
.btn-age[disabled]:hover,
fieldset[disabled] .btn-age:hover,
.btn-age.disabled:focus,
.btn-age[disabled]:focus,
fieldset[disabled] .btn-age:focus,
.btn-age.disabled:active,
.btn-age[disabled]:active,
fieldset[disabled] .btn-age:active,
.btn-age.disabled.active,
.btn-age[disabled].active,
fieldset[disabled] .btn-age.active {
  background-color: #56251a;
  border-color: #2f0900;
}

.btn-age .badge {
  color: #56251a;
  background-color: #ffffff;
}

.clear-both {
  clear: both;
}

.text-center {
  text-align: center;
}

.btn {
  font-family: sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  border-radius: 40px;
  padding: 8px 30px;
  margin-bottom: 5px;
  -webkit-transition:
    color 0.3s,
    background-color 0.3s,
    border-color 0.3s;
  transition:
    color 0.3s,
    background-color 0.3s,
    border-color 0.3s;
  white-space: normal;
  word-wrap: break-word;
  border-radius: 10px;
}

.btn.btn-lg {
  padding: 10px 35px;
  letter-spacing: 2px;
  font-size: 21px;
}

.btn.btn-sm {
  padding: 7px 20px;
  font-size: 14px;
}

.btn.btn-xs {
  padding: 5px 15px;
  font-size: 12px;
}

.btn-default {
  color: #676767;
  border-color: #e5e5e5;

  &:hover {
    color: #fff;
    background-color: #d9d3c3;
    border-color: #d9d3c3;
  }
}

.btn-default-o {
  color: #f2f2f2;
  border-color: #f2f2f2;
  background-color: transparent;

  &:hover {
    color: #d9d3c3;
    background-color: #fff;
    border-color: #fff;
  }
}

.btn-main-o {
  color: #d9d3c3;
  border-color: #d9d3c3;
  background-color: transparent;

  &:hover {
    color: #fff;
    background-color: #d9d3c3;
    border-color: #d9d3c3;
  }
}

.btn-dark-o {
  color: #56251a;
  border-color: #56251a;
  background-color: transparent;

  &:hover {
    color: #fff;
    background-color: #56251a;
  }
}

.btn-dark {
  color: #fff;
  background-color: #56251a;
  border-color: #56251a;

  &:hover,
  &:focus,
  &:active {
    background-color: #bc8f8f;
  }
}

.btn-round-dark {
  background-color: rgba(255, 255, 255, 0.7);
  color: #56251a;
  border-color: #56251a;
  font-weight: bold;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    background-color: #bc8f8f;
  }

  i {
    color: #56251a;
    display: block;
  }
}

.btn-main {
  color: #000;
  background-color: #d9d3c3;
  border-color: #d9d3c3;

  &:hover,
  &:focus,
  &:active {
    background-color: #d9d3c3;
  }
}

.btn-link {
  font-weight: 400;
  color: #d9d3c3;

  &:hover,
  &:focus,
  &:active {
    color: #4c4c4c;
    text-decoration: none;
  }
}

.ft-1-5 {
  font-size: 1.5rem;
}

@font-face {
  font-family: "godofwarregular";
  src: url("../fonts/godofwar-webfont.eot");
  src:
    url("../fonts/godofwar-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/godofwar-webfont.woff") format("woff"),
    url("../fonts/godofwar-webfont.ttf") format("truetype"),
    url("../fonts/godofwar-webfont.svg#godofwarregular") format("svg");
  font-weight: normal;
  font-style: normal;
}
