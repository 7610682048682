article[class^="special-rules-"] {
  min-height: 100px;
  cursor: pointer;

  > h1,
  > h4 {
    padding: 10px;
    margin: 0;
  }
}
