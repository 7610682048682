.multi-color-progress-bar {
  margin: 5px 15px;

  .values .value {
    float: left;
    text-align: center;
  }

  .scale .graduation {
    float: left;
    text-align: center;
  }

  .bars {
    .bar {
      float: left;
      height: 10px;

      &:first-of-type {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      &:last-of-type {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }

  .legends {
    text-align: center;

    .legend {
      display: inline-block;
      margin: 0 5px;
      text-align: center;

      .dot {
        font-size: 25px;
        vertical-align: middle;
      }

      .label {
        margin-left: 2px;
        vertical-align: middle;
      }
    }
  }
}
