.apple_pagination {
  text-align: center;
  padding: 1em;
  cursor: default;
  clear: both;
  //  padding-bottom: 80px;

  a,
  span {
    padding: 0.2em 0.3em;
  }
  .disabled {
    color: #aaaaaa;
  }
  .current {
    font-style: normal;
    font-weight: bold;
    background-color: #2f0900;
    color: white;
    display: inline-block;
    width: 1.4em;
    height: 1.4em;
    line-height: 1.5;
    -moz-border-radius: 1em;
    -webkit-border-radius: 1em;
    border-radius: 1em;
    text-shadow: rgba(255, 255, 255, 0.8) 1px 1px 1px;
  }
  a {
    text-decoration: none;
    color: #2f0900;
  }
  a:hover,
  a:focus {
    text-decoration: underline;
  }
}

.flickr_pagination {
  text-align: center;
  padding: 0.3em;
  cursor: default;
  clear: both;

  a,
  span,
  em {
    padding: 0.2em 0.5em;
  }
  .disabled {
    color: #aaaaaa;
  }
  .current {
    font-style: normal;
    font-weight: bold;
    color: #ff0084;
  }
  a {
    border: 1px solid #dddddd;
    color: #0063dc;
    text-decoration: none;
  }
  a:hover,
  a:focus {
    border-color: #003366;
    background: #0063dc;
    color: white;
  }
  .page_info {
    color: #aaaaaa;
    padding-top: 0.8em;
  }
  .previous_page,
  .next_page {
    border-width: 2px;
  }
  .previous_page {
    margin-right: 1em;
  }
  .next_page {
    margin-left: 1em;
  }
}
